$color_black: #333;
$color_text: #929292;
$color_primary: #DDD;
$color_secondary: #999;
$color_danger: #ff0000;
$color_border: #E0E0E0;
$color_disabled: #ebebeb;
$color_button: #86d031;

$border_radius: 0;
$border_width: 1px;

$padding_top_bottom: 15px;
$padding_left_right: 15px;

$font_size_floating_label: 12px;

$font__size_tab_heading: 20px;
$font__size_modal_heading: 24px;