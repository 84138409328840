.linq-form {
    position: relative;

    .results-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: -1;
        color: $color_black;
        @include transition(opacity 300ms ease-in-out);

        &.active {
            opacity: 1;
            z-index: 2;
            background-color: rgba(255, 255, 255, .8);
            @include transition(opacity 300ms ease-in-out);

            .inner {
                opacity: 0;
                z-index: -1;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                max-width: 480px;
                padding: 30px 15px;
                @include transform(translateY(-50%));

                &.active {
                    opacity: 1;
                    z-index: 3;
                }
            }
        }

        .loader {
            @extend .spinner--cube; // from ./animations.scss
        }

        .reset-btn {
            cursor: pointer!important;
            width: 100px;
            margin: 10px auto;
        }
    }

    .waiting-box {
        @extend .results-box;

        &.active .inner {
            opacity: 1;
            z-index: 3;
        }
    }

    .response-box {
        @extend .results-box;
    }

    form {

        input,
        textarea,
        select {
            margin: 0;
            width: 100%;
            box-shadow: none;
            font-weight: normal;
            font-size: 14px;
            color: $color_text;
            border: $border_width solid;
            border-color: $color_border;
            border-radius: $border_radius;
            padding: $padding_top_bottom $padding_left_right;
        }

        label {
            margin-bottom: 0;
            font-weight: normal;
            font-size: 14px;
            color: $color_text;
            padding: ($padding_top_bottom + $border_width) ($padding_left_right + $border_width);
        }

        textarea {
            resize: none;
        }

        select {
            position: relative;
            height: 52px;
            border-radius: 0;
            font-weight: normal;
            background-color: #FFF;
            -webkit-appearance: none;
        }

        button {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            color: #FFF;
            padding: 0px;
            border: none;
            height: 52px;
            width: 100%;
            max-width: 150px;
            background-color: $color_button;
            @include transition(300ms ease-in-out background-color);
            
            @include hover-state {
                background-color: lighten($color_button, 8%);
                @include transition(300ms ease-in-out background-color);
            }
        }

        .form-block {

            &.left-side {
                float: left;
                width: 50%;
                padding-right: 5px;
            }

            &.right-side {
                float: left;
                width: 50%;
                padding-left: 5px;
            }

            &.center-side {
                clear: both;
            }

            .linq-form-row {
                padding-bottom: 10px;
            }
        }

        .linq-form-field {

            &.floating-placeholder {
                position: relative;

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include transition(all 300ms ease-in-out);
                }

                &.linq-form-field-float {

                    label {
                        font-size: $font_size_floating_label;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            &.hasError {

                label {
                    color: $color_danger;
                }

                input, textarea, select {
                    border: 1px solid;
                    border-color: $color_danger;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {

        form {

            .form-block {

                &.left-side,
                &.right-side {
                    float: none;
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}