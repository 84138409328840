

input[type="checkbox"]:after, input[type="checkbox"]:active:after:after {
  border-radius: 2px;
  width: 9px;
  height: 9px;
  padding: 2px;
  box-shadow: 0 0 0 2px #EEE;
  content: '';
  display: inline-block;
}

input[type="checkbox"] {
  background: #EEE;
  box-shadow: 0 0 0 0 #F68121 inset, 0 0 0 2px #F68121 inset, 0 0 0 8px #FFF inset, 0 0 0 0 #FFF inset, 0 0 0 0 #FFF;
  padding: 8px;
  border-radius: 2px;
  display: block;
  transition: all .2s;
  zoom: 2;
}


input[type="checkbox"]:checked:active {
  background: #F68121;
  box-shadow: 0 0 0 8px #F68121 inset, 0 0 0 0 #666666 inset, 0 0 0 8px #EEE inset, 0 0 0 12px #ddd inset, 0 0 0 8px #D6E5E4;
}

input[type="checkbox"]:checked {
  background: #F9F9F9;
  box-shadow: 0 0 0 8px #F68121 inset, 0 0 0 0 #666666 inset, 0 0 0 8px #EEE inset, 0 0 0 0 #ddd inset, 0 0 0 0 #ddd;
  content: url('../images/checkbox.png');
}

input[type="checkbox"]:active {
  background: #ddd;
  box-shadow: 0 0 0 0 #F68121 inset, 0 0 0 2px #666666 inset, 0 0 0 0 #EEE inset, 0 0 0 8px #ddd inset, 0 0 0 12px #ddd;
}